export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({ id, errors }: { errors?: ListOfErrors; id?: string }) {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;
  return (
    <ul id={id} className="m-0 flex list-none flex-col gap-1 p-0">
      {errorsToRender.map((e) => (
        <li key={e} className="text-foreground-destructive m-0 p-0">
          {e}
        </li>
      ))}
    </ul>
  );
}
